var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-form kt-form--label-right" }, [
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "kt-section kt-section--first" }, [
        _vm.$_userMixins_isSuperAdmin
          ? _c("div", { staticClass: "alert alert-solid-primary alert-bold" }, [
              _c("div", { staticClass: "alert-text" }, [
                _vm._v(
                  " Send Password Reset email for user you are impersonating "
                ),
              ]),
              _c("div", { staticClass: "alert-close" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning btn-bold btn-upper btn-sm",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.sendPasswordReset.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Send Password Reset ")]
                ),
              ]),
            ])
          : _vm._e(),
        _c(
          "h3",
          { staticClass: "kt-section__title kt-section__title-sm w-100" },
          [_vm._v(" Change Password: ")]
        ),
        _c("div", { staticClass: "kt-section__body" }, [
          _c("div", { staticClass: "password-requirements" }, [
            _c(
              "div",
              { staticClass: "alert alert-light", attrs: { role: "alert" } },
              [
                _c("div", { staticClass: "alert-icon" }, [
                  !_vm.isValidNewPassword
                    ? _c("i", {
                        staticClass: "flaticon-warning kt-font-warning",
                      })
                    : _c("i", {
                        staticClass: "flaticon2-check-mark kt-font-success",
                      }),
                ]),
                _c("div", { staticClass: "alert-text ml-3" }, [
                  _c("span", { staticClass: "ml-5" }, [
                    _vm._v(" Password Requirements: "),
                  ]),
                  _c("ul", [
                    _c(
                      "li",
                      { class: { is_valid: _vm.confirmPassword?.length >= 8 } },
                      [_vm._v(" 8 Characters ")]
                    ),
                    _c(
                      "li",
                      { class: { is_valid: /\d/.test(_vm.confirmPassword) } },
                      [_vm._v(" Contains Number ")]
                    ),
                    _c(
                      "li",
                      {
                        class: { is_valid: /[A-Z]/.test(_vm.confirmPassword) },
                      },
                      [_vm._v(" Contains Uppercase ")]
                    ),
                    _c(
                      "li",
                      {
                        class: {
                          is_valid: _vm.format.test(_vm.confirmPassword),
                        },
                      },
                      [_vm._v(" Contains Special Character ")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "form-group validated row" }, [
            _c("div", { staticClass: "p-3 pr-4 col-lg-4" }, [
              _vm._v(" New Password "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newPassword,
                    expression: "newPassword",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.newPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.newPassword = $event.target.value
                  },
                },
              }),
              !_vm.newPassword
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(" Please enter your new password. "),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "p-3 pr-4 col-lg-4" }, [
              _vm._v(" Confirm Password "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.confirmPassword,
                    expression: "confirmPassword",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.confirmPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.confirmPassword = $event.target.value
                  },
                },
              }),
              !_vm.confirmPassword || !_vm.isValidNewPassword
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(" Please confirm your new password. "),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "p-4 mb-2" }, [
            _c("div", { staticClass: "kt-form__actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-bold btn-primary ml-3 pull-right",
                  class: {
                    "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                  },
                  attrs: { type: "button", disabled: !_vm.isValidPassword },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.savePassword.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.saving
                    ? _c("i", { staticClass: "fa fa-save" })
                    : _vm._e(),
                  _vm.saving
                    ? [_vm._v(" Saving... ")]
                    : [_vm._v(" Save Password ")],
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }