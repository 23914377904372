<template>
<div class="kt-form kt-form--label-right">
    <div class="kt-portlet__body">
        <div class="kt-section kt-section--first">
            <div
                v-if="$_userMixins_isSuperAdmin"
                class="alert alert-solid-primary alert-bold"
            >
                <div class="alert-text">
                    Send Password Reset email for user you are impersonating
                </div>
                <div class="alert-close">
                    <button
                        type="button"
                        class="btn btn-warning btn-bold btn-upper btn-sm"
                        @click.stop.prevent="sendPasswordReset"
                    >
                        Send Password Reset
                    </button>
                </div>
            </div>
            <h3 class="kt-section__title kt-section__title-sm w-100">
                Change Password:
            </h3>
            <div class="kt-section__body">
                <div class="password-requirements">
                    <div
                        class="alert alert-light"
                        role="alert"
                    >
                        <div class="alert-icon">
                            <i
                                v-if="!isValidNewPassword"
                                class="flaticon-warning kt-font-warning"
                            />
                            <i
                                v-else
                                class="flaticon2-check-mark kt-font-success"
                            />
                        </div>
                        <div class="alert-text ml-3">
                            <span class="ml-5">
                                Password Requirements:
                            </span>
                            <ul>
                                <li :class="{ 'is_valid': confirmPassword?.length >= 8 }">
                                    8 Characters
                                </li>
                                <li :class="{ 'is_valid': /\d/.test(confirmPassword) }">
                                    Contains Number
                                </li>
                                <li :class="{ 'is_valid': /[A-Z]/.test(confirmPassword) }">
                                    Contains Uppercase
                                </li>
                                <li :class="{ 'is_valid': format.test(confirmPassword) }">
                                    Contains Special Character
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="form-group validated row">
                    <div class="p-3 pr-4 col-lg-4">
                        New Password
                        <input
                            v-model="newPassword"
                            type="text"
                            class="form-control"
                        >
                        <div
                            v-if="!newPassword"
                            class="invalid-feedback"
                        >
                            Please enter your new password.
                        </div>
                    </div>
                    <div class="p-3 pr-4 col-lg-4">
                        Confirm Password
                        <input
                            v-model="confirmPassword"
                            type="text"
                            class="form-control"
                        >
                        <div
                            v-if="!confirmPassword || !isValidNewPassword"
                            class="invalid-feedback"
                        >
                            Please confirm your new password.
                        </div>
                    </div>
                </div>
                <div class="p-4 mb-2">
                    <div class="kt-form__actions">
                        <button
                            type="button"
                            class="btn btn-bold btn-primary ml-3 pull-right"
                            :disabled="!isValidPassword"
                            :class="{
                                'kt-spinner kt-spinner--sm kt-spinner--light': saving,
                            }"
                            @click.stop.prevent="savePassword"
                        >
                            <i
                                v-if="!saving"
                                class="fa fa-save"
                            />
                            <template v-if="saving">
                                Saving...
                            </template>
                            <template v-else>
                                Save Password
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import * as network from '../../network';
import userMixins from '../../store/mixins/userMixins';
import Types from '../../store/Types';

export default {
    name: 'Password',
    mixins: [userMixins],
    data() {
        return {
            newPassword: null,
            confirmPassword: null,
            format: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            saving: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        isValidNewPassword() {
            const { newPassword, confirmPassword, format } = this;
            if (!newPassword || !confirmPassword) return false;
            if (newPassword !== confirmPassword) return false;
            if (confirmPassword.length < 8) return false;

            if (/\d/.test(confirmPassword) === false) return false;
            if (/[A-Z]/.test(confirmPassword) === false) return false;
            if (!format.test(confirmPassword)) return false;
            return true;
        },
        isValidPassword() {
            return this.isValidNewPassword;
        },
    },
    mounted() {
    },
    methods: {
        savePassword() {
            const v = this;
            if (v.saving || !v.isValidPassword) return;
            v.saving = true;

            const { confirmPassword, user } = v;
            const params = {
                body: {
                    newPassword: confirmPassword,
                },
            };

            network.authLocalPasswords.changePassword(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Error changing password');
                if (!res.success && res.errors[0] === 'current') return v.showError('Incorrect Old Password');
                if (res.success) {
                    v.newPassword = null;
                    v.confirmPassword = null;
                    v.$store.commit(Types.mutations.SET_USER_PROFILE, { ...user, hasLocalPassword: true });
                    return v.showNotification('Password changed successfully');
                }
            });
        },
        sendPasswordReset() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { user } = v;
            const { mappedUserName, userName } = user;

            const params = {
                body: {
                    emailAddress: mappedUserName || userName,
                },
            };

            network.authLocalPasswords.forgotPassword(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Error sending password reset email');
                const { status } = res;
                if (status === 'ok') v.showNotification('Password reset email sent successfully');
            });
        },
    },
};
</script>

<style scoped>
.password-requirements {
    margin-top: -2rem !important;
    padding-top: 0 !important;
    margin-left: 2rem !important;
}
</style>
